import styles from './Hero.module.css';

const Hero = ( ) => {
  return (
    <section className={styles.hero}>
        <div className={styles.container}>
            <div className={styles.container_1}>
                <img loading="lazy" src="/images/hero/image-28.webp" width="90px" height="120px" alt="Not found" />
            </div>
            <div className={styles.container_2}>
                <img loading="lazy" src="/images/hero/image-30.webp" width="90px" height="100px" alt="Not found" />
                <img loading="lazy" src="/images/hero/image-26.webp" width="90px" height="120px" alt="Not found" />

            </div>
            <div className={styles.container_3}>
                <img loading="lazy" src="/images/hero/image-27.webp" width="90px" height="120px" alt="Not found" />
                <img loading="lazy" src="/images/hero/image-29.webp" width="90px" height="120px" alt="Not found" />
            </div>
            <div className={styles.container_4}>
                <img loading="lazy" src="/images/hero/image-22.webp" width="90px" height="120px" alt="Not found" />
                <img loading="lazy" src="/images/hero/image-23.webp" width="90px" height="120px" alt="Not found" />
            </div >
            <div className={styles.container_5}>
                <img loading="lazy" src="/images/hero/image-25.webp" width="90px" height="120px" alt="Not found" />
                <img loading="lazy" src="/images/hero/image-24.webp" width="90px" height="120px" alt="Not found" />
            </div>
        </div>
        <h1>Online Experiences</h1>
        <p>Join unique interactive activities led by 
        one-of-a-kind hosts—all without leaving home.</p>
    </section>
  )
}

export default Hero;