import styles from './Card.module.css';
import { data } from '../data/data';

const Card = () => {
  return (
    <section className={styles.section}>
        {data &&
        data.map((item) => (
            <div key={item.id} className={styles.card}>

                {/* BADGE TAGS */}
                {(!item.openSpots && <div className={styles.card_badge}>SOLD OUT</div>) || 
                (item.location === "Online" && <div className={styles.card_badge}>ONLINE</div>)}

                <img className={styles.card_img} loading="lazy" src={`/images/card/${item.coverImg}`} width="175px" height="250px" alt="Not found" />
                <div className={styles.info}>
                    <img className={styles.star} src="/images/card/star.webp" width="14px" height="14px" alt="Not found" />
                    <span>{item.stats.rating} &nbsp;</span>
                    <p>({item.stats.reviewCount}) - {item.location}</p>
                </div>
                <p>{item.title}</p>
                <p><span>From ${item.price}&nbsp;</span>/person</p>
            </div>
        ))}
    </section>
  )
}

export default Card