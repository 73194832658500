import styles from './NavBar.module.css';

const NavBar = () => {
  return (
    <nav className={styles.nav}>
      <img className={styles.logo} src="/airbnb.svg" width="96px" height="30px" alt="airbnb logo" href="/" />
    </nav>
  )
}

export default NavBar;