import Hero from "../pages/Hero";
import Card from "../pages/Card";

const Home = () => {
  return (
    <div>
        <Hero/>
        <Card/>
    </div>
  )
}

export default Home